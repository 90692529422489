import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import {ConfirmationService, ConfirmEventType, MessageService} from 'primeng/api';
import { DatePipe } from '@angular/common';
import { PaymentsPlanService } from '@support/services/payments-plan.service';

@Component({
  selector: 'app-create-plan',
  templateUrl: './create-plan.component.html',
  styleUrls: ['./create-plan.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class CreatePlanComponent implements OnInit {

  @Input() visible: boolean = false;
  @Input() business: any = null;

  @Output() showPlanForm = new EventEmitter<boolean>();
  @Output() isSuccess = new EventEmitter<boolean>();

  public form: FormGroup;
  public disabled: boolean = true;
  public validPercentageForm: boolean = true;
  public validAmountsForm: boolean = true;
  public percentageData: any;
  public amountsData: any;

  public options: any[] = [];
  public percentMessages: any[] = [];
  public selectedOption: any = null;
  public diff: number = null;
  public quotes: any[] = [];
  public loading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private _paymentsPlan: PaymentsPlanService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.setOptions();
    this.createForm();
  }

  setOptions(): void {
    this.options = [
      {label: 'Manual', id: 1},
      {label: 'Porcentajes', id: 2},
      {label: 'Montos Fijos', id: 3}
    ];
  }

  createForm(): void {
    this.form = this.fb.group({
      creation_type: ['', Validators.required],
      property_value: [null, Validators.required],
      final_value: [null, Validators.required],
      total_amount: [null, Validators.required],
      diference: [null, Validators.required],
    });

    this.form.controls['property_value'].disable();
    this.form.controls['final_value'].disable();
    this.form.controls['total_amount'].disable();
    this.form.controls['diference'].disable();
    this.completeForm();
  }

  completeForm(): void {
    if (this.business) {
      this.form.patchValue({
        property_value: this.business?.property?.price_base,
        final_value: this.business?.property?.price_base,
      });
    }
  }

  changeOption($event): void {
    if (this.selectedOption) {
      if (this.selectedOption === $event.value) return;
      if (this.diff !== null) {
        const oldSelected = this.selectedOption;
        this.confirmationService.confirm({
          message: 'Al cambiar el tipo de creación perderá todas la información guardada hasta el momento',
          header: 'Confirmación',
          accept: () => {
            this.selectedOption = $event.value;
            this.form?.get('total_amount').setValue(null);
            this.form?.get('diference').setValue(null);
            this.diff = null;
          },
          reject: (type) => {
            switch(type) {
              case ConfirmEventType.REJECT:
                this.selectedOption = oldSelected;
                this.form.patchValue({
                  creation_type: this.selectedOption
                });
                break;
                case ConfirmEventType.CANCEL:
                  this.selectedOption = oldSelected;
                  this.form.patchValue({
                    creation_type: this.selectedOption
                  });
                break;
            }
          }
        });
        return;
      }
    }
    this.selectedOption = $event.value;
    this.form?.get('total_amount').setValue(null);
    this.form?.get('diference').setValue(null);
    this.diff = null;
  }

  setTotalPlan($event): void {
    this.diff = this.business?.property?.price_base - parseFloat($event.toFixed(2));
    this.diff = parseFloat(this.diff.toFixed(2));
    this.form?.get('total_amount').setValue(parseFloat($event.toFixed(2)));
    this.form?.get('diference').setValue(this.diff);
    if (this.diff >= -1 && this.diff <= 1) {
      console.log('aqui');
      this.disabled = false;
    } else{
      this.disabled = true;
    }
  }

  setQuotes($event): void {
    this.quotes = $event;
  }

  async createPlan(): Promise<void> {
    if(this.disabled) return;
    this.loading = true;
    let data =  {
      ...this.form.getRawValue(),
      business_id: this.business?.id,
      type: this.form.get('creation_type').value === 1
        ? 'Manual' : this.form.get('creation_type').value === 2
        ? 'Porcentajes' : 'Montos fijos'
    }
    switch (data.type) {
      case 'Manual':
        data = {
          ...data,
          quotes: this.quotes
        }
        break;

        case 'Porcentajes':
          const {
            reserveFormatted: reserve,
            separationFormatted: separation,
            quoteFormatted: quote,
            deliveryFormatted: delivery
          } = await this.setDates(this.percentageData);
          data = {
            ...data,
            ...this.percentageData,
            reserve_date: reserve,
            separation_date: separation,
            start_date: quote,
            delivery_date: delivery
          }
        break;
        case 'Montos fijos':
          const {
            reserveFormatted,
            separationFormatted,
            quoteFormatted,
            deliveryFormatted
          } = await this.setDates(this.amountsData);
          data = {
            ...data,
            ...this.amountsData,
            reserve_date: reserveFormatted ,
            separation_date: separationFormatted ,
            start_date: quoteFormatted ,
            delivery_date: deliveryFormatted
          }
        break;

      default:
        break;
    }

    this._paymentsPlan.create(data).subscribe(
      (res:any)=>{
        if (res.res) {
          this.showNotify('success', 'Completado', 'El plan de pagos se ha creado con éxito');
          setTimeout(()=>{
            this.loading = false;
            this.isSuccess.emit(true);
            this.closeSidebar();
          }, 4000);
        }
      },
      (err:any)=>{
        this.showNotify('error', 'Falló', 'Ha ocurrido un error al crear el plan de pagos');
        setTimeout(()=>{
          this.loading = false;
          this.isSuccess.emit(false);
          this.closeSidebar();
        }, 4000);
      }
    )
  }

  closeSidebar(): void {
    this.visible = false;
    this.form.reset();
    this.showPlanForm.emit(false);
  }

  async setPercentageForm(event): Promise<void> {
    console.log({event, file: 'create-plan ~ 220'});
    if (!event || Object.values(event).some(value => value === null || value === '')) {
      let quotes = event.quote_count ? event.quote_count * event.quote_amount : event.quote_amount;
      quotes = parseFloat(quotes.toFixed(2));
      let separation = event.reserve_amount ? event.separation_amount + event.reserve_amount : event.separation_amount;
      separation = parseFloat(separation.toFixed(2));
      let sum = event.delivery_amount + quotes + separation;
      sum = parseFloat(sum.toFixed(2));
      this.diff = await this.business?.property?.price_base - sum;
      this.diff = await parseFloat(this.diff.toFixed(2));
      this.form?.get('total_amount').setValue(sum);
      this.form?.get('diference').setValue(this.diff);
      this.validPercentageForm = false;
      if (this.percentMessages.length > 0) {
        const finded = this.percentMessages.find(item => item.label === 'Por favor complete el formulario');
        if (!finded) {
          this.percentMessages = [...this.percentMessages, {label: 'Por favor complete el formulario'}];
        }
      } else {
        this.percentMessages = [...this.percentMessages, {label: 'Por favor complete el formulario'}];
      }
      return;
    }
    const pq = parseFloat(event.quotes_percentage.toFixed(2));
    const ps = parseFloat(event.separation_percentage);
    const pd = parseFloat(event.delivery_percentage.toFixed(2));
    const percent_sum = pq + ps+ pd;
    if (percent_sum !== 100) {
      if (this.percentMessages.length > 0) {
        const finded = this.percentMessages.find(item => item.label === 'La suma de los campos % debe ser igual a 100');
        if (!finded) {
          this.percentMessages = [...this.percentMessages, {label: 'La suma de los campos % debe ser igual a 100'}];
        }
      } else {
        this.percentMessages = [...this.percentMessages, {label: 'La suma de los campos % debe ser igual a 100'}];
      }
      this.validPercentageForm = false;
      return;
    }
    this.validPercentageForm = true;
    this.percentageData = event;
    if (this.diff >= -1 && this.diff <= 1) {
      this.disabled = false;
    } else{
      this.disabled = true;
    }
    this.cdr.markForCheck();
  }

  setAmountsForm(event): void {
    if (!event || Object.values(event).some(value => value === null || value === '')) {
      const reserve = event.reserve_amount || 0;
      const separation = event.separation_amount || 0;
      const delivery = event.delivery_amount || 0;
      const quotes =
        event.quote_count && event.quote_amount
        ? event.quote_count * event.quote_amount
        : event.quote_amount ? event.quote_amount : 0;
      let sum = reserve + separation + delivery + quotes;
      sum = parseFloat(sum.toFixed(2));
      this.diff = this.business?.property?.price_base - sum;
      this.diff = parseFloat(this.diff.toFixed(2));
      this.form?.get('total_amount').setValue(sum);
      this.form?.get('diference').setValue(this.diff);
      this.validAmountsForm = false;
      return;
    }
    this.amountsData = event;
    if (this.diff >= -1 && this.diff <= 1) {
      this.validAmountsForm = true;
      this.disabled = false;
    } else{
      this.validAmountsForm = false;
      this.disabled = true;
    }
  }

  showNotify(severity, summary, detail): void {
    this.messageService.add({key: 'tr', severity: severity, summary: summary, detail: detail});
  }

  ngOnDestroy() {
    this.showPlanForm.emit(false);
  }

  setDates(data): any {
    const reserve = data.reserve_date;
    const reserveFormatted = this.datePipe.transform(reserve, 'yyyy-MM-dd');
    const separation = data.separation_date;
    const separationFormatted = this.datePipe.transform(separation, 'yyyy-MM-dd');
    const quote = data.start_date;
    const quoteFormatted = this.datePipe.transform(quote, 'yyyy-MM-dd');
    const delivery = data.delivery_date;
    const deliveryFormatted = this.datePipe.transform(delivery, 'yyyy-MM-dd');
    return {reserveFormatted, separationFormatted, quoteFormatted, deliveryFormatted};
  }

}
