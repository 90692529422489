<p-dialog header="Cuota" [(visible)]="displayModal" [closable]="false" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">
  <p-toast position="top-right" key="tr" [baseZIndex]="20000"></p-toast>
  <div class="container">
    <form [formGroup]="form">
      <div class="row my-5">
        <div class="col-lg-6 col-md-12 my-2 md:my-0">
          <span class="p-float-label">
            <p-inputNumber class="w-100" inputId="number" formControlName="number"></p-inputNumber>
            <label for="number">Número de cuota *</label>
            <small *ngIf="showError('number')" id="number-help" class="p-error block">El número ingresado ya esta en uso</small>
          </span>
        </div>
        <div class="col-lg-6 col-md-12 my-2 md:my-0">
          <span class="p-float-label">
            <input class="w-100"  id="name" type="text" formControlName="name" pInputText>
            <label for="name">Nombre *</label>
            <small *ngIf="showError('name')" id="name-help" class="p-error block">El Campo nombre es requerido</small>
          </span>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-lg-6 col-md-12 my-2 md:my-0">
          <span class="p-float-label">
            <p-calendar appendTo="body" formControlName="date" [disabledDates]="invalidDates" [minDate]="minDate" [maxDate]="maxDate" styleClass="w-100" placeholder="Fecha de pago" id="date"  [showIcon]="true" inputId="icon" dateFormat="dd/mm/yy"></p-calendar>
            <label for="date">Fecha *</label>
            <small *ngIf="showError('date')" id="date-help" class="p-error block">El Campo fecha es requerido</small>
          </span>
        </div>
        <div class="col-lg-6 col-md-12 my-2 md:my-0">
          <span class="p-float-label">
            <input currencyMask formControlName="amount" class="w-100" [options]="{ prefix: '', thousands: ',', decimal: '.' }" id="amount" type="text" pInputText>
            <label for="amount">Monto *</label>
            <small *ngIf="showError('amount')" id="amount-help" class="p-error block">El Campo monto es requerido</small>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-end flex-wrap">
          <p-button label="Guardar" icon="pi pi-check" styleClass="p-button-text p-button-secondary" (click)="saveQuote()"></p-button>
          <p-button label="Cancelar" icon="pi pi-times" styleClass="p-button-text" (click)="closeModal()"></p-button>
        </div>
      </div>
    </form>
  </div>
</p-dialog>
