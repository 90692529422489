<p-toast position="top-right" key="tr" [baseZIndex]="20000"></p-toast>
<app-spinner *ngIf="loading"></app-spinner>
<div *ngIf="!loading" class="container">
  <div class="row">
    <div class="col-12">
      <p-divider align="left">
        <div class="inline-flex align-items-center">
            <i class="pi pi-wallet mr-2"></i>
            <b>Totalización</b>
        </div>
      </p-divider>
    </div>
  </div>
  <form *ngIf="paymentsPlan" [formGroup]="form">
    <div class="row">
      <div class="col-6 col-md-3 my-2 md:my-0">
        <span class="p-float-label">
          <input currencyMask formControlName="property_value" class="w-100" [options]="{ prefix: '', thousands: ',', decimal: '.' }" id="property_value" type="text" pInputText>
          <label for="property_value">Precio del inmueble *</label>
        </span>
      </div>
      <div class="col-6 col-md-3 my-2 md:my-0">
        <span class="p-float-label">
          <input currencyMask formControlName="final_value" class="w-100" [options]="{ prefix: '', thousands: ',', decimal: '.' }" id="final_value" type="text" pInputText>
          <label for="final_value">Precio final *</label>
        </span>
      </div>
      <div class="col-6 col-md-3 my-2 md:my-0">
        <span class="p-float-label">
          <input currencyMask formControlName="total_amount" class="w-100" [options]="{ prefix: '', thousands: ',', decimal: '.' }" id="total_amount" type="text" pInputText>
          <label for="total_amount">Total plan de pagos *</label>
        </span>
      </div>
      <div class="col-6 col-md-3 my-2 md:my-0">
        <span class="p-float-label">
          <input currencyMask formControlName="diference" class="w-100" [options]="{ prefix: '', thousands: ',', decimal: '.' }" id="diference" type="text" pInputText>
          <label for="diference">Diferencia *</label>
        </span>
      </div>
    </div>
  </form>
  <div *ngIf="diff < -1 || diff > 1" class="row mt-3">
    <div class="col-12">
      <p-message styleClass="w-100 message-custom" severity="error" text="El monto de la diferencia no puede ser menor a -1 ni mayor a 1"></p-message>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <p-divider align="left">
        <div class="inline-flex align-items-center">
            <i class="pi pi-table mr-2"></i>
            <b>Matriz</b>
        </div>
      </p-divider>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <app-plan-list
        [list]="paymentsPlan?.quotes"
        [paymentsPlan]="paymentsPlan"
        [business]="business"
        [permissions]="permissions"
        (quotesForDelete)="deleteQuotes($event)"
        (totalPlan)="setTotalPlan($event)"
        (isSaveButtonAble)="setSaveButtonstatus($event)"
        (quotesList)="setQuotes($event)"
        (status)="emitStatus($event)"
        (paymentPlanDelete)="deletePaymentPlan($event)"
        (reconciliate)="reconciliation($event)"
      ></app-plan-list>
    </div>
  </div>
</div>
