import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-upload-main-imagen',
  templateUrl: './upload-main-imagen.component.html',
  styleUrls: ['./upload-main-imagen.component.css']
})
export class UploadMainImagenComponent implements OnInit, OnDestroy{

  public fileSelected: File;
  public draft = false;
  public placeholder = "https://via.placeholder.com/150";
  public image:string;
  public imageSelectedSub: Subscription;
  @Input() imagePreload:string;
  @Input() label: string = '';
  @Output() imageMainEvent = new EventEmitter<File>();
  product: any;
  constructor(){}

  ngOnInit(): void {
    this.image = this.imagePreload ? this.imagePreload : this.placeholder;
    console.log(this.image);
  }


  changeFile(event): void {
    const files = event.target.files;
    if (files.length > 0) {
      this.fileSelected = files[0];
      this.imageMainEvent.emit(this.fileSelected);

      const reader = new FileReader();
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.image = reader.result as string;
        /**Guardar para previsualizar */
        localStorage.setItem('images', this.image);
      };
    }
  }

  getImagesPreviews():void{
    const images = localStorage.getItem('images');
    if(images) {
      this.image = images;
      this.draft = true
    }else{
      this.draft = false;
    }
  }

  ngOnDestroy(): void {
      // this.imageSelectedSub?.unsubscribe();
  }
}
