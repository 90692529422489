<p-sidebar id="create-plan" styleClass="px-1 py-1 md:px-5 md:py-3" [(visible)]="visible" [showCloseIcon]="false" [fullScreen]="true" [baseZIndex]="10000">
  <ng-template pTemplate="header">
    <h2 class="plan-title d-flex align-items-center">
      <i class="pi pi-money-bill mr-2 pt-1" style="font-size: 1.5rem;"></i>
      <strong>
          Creación de plan de pagos
      </strong>
    </h2>
  </ng-template>
  <ng-template pTemplate="content">
    <p-progressSpinner *ngIf="loading" styleClass="custom-spinner" strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s"></p-progressSpinner>
    <p-toast position="top-right" key="tr" [baseZIndex]="20000"></p-toast>
    <form *ngIf="!loading" style="padding-top: 2rem;" [formGroup]="form">
      <div class="row justify-content-around">
        <div class="col-lg-2 col-md-12 my-2 md:my-0">
          <span class="p-float-label">
            <p-dropdown id="type" formControlName="creation_type" styleClass="w-100" appendTo="body" [options]="options" placeholder="Seleccione una opción" optionLabel="label" optionValue="id" [showClear]="false" (onChange)="changeOption($event)"></p-dropdown>
            <label for="type">Tipo de creación *</label>
          </span>
        </div>
        <div class="col-lg-2 col-md-12 my-2 md:my-0">
          <span class="p-float-label">
            <input currencyMask formControlName="property_value" class="w-100" [options]="{ prefix: '', thousands: ',', decimal: '.' }" id="property_value" type="text" pInputText>
            <label for="property_value">Precio del inmueble *</label>
          </span>
        </div>
        <div class="col-lg-2 col-md-12 my-2 md:my-0">
          <span class="p-float-label">
            <input currencyMask formControlName="final_value" class="w-100" [options]="{ prefix: '', thousands: ',', decimal: '.' }" id="final_value" type="text" pInputText>
            <label for="final_value">Precio Final *</label>
          </span>
        </div>
        <div class="col-lg-2 col-md-12 my-2 md:my-0">
          <span class="p-float-label">
            <input currencyMask formControlName="total_amount" class="w-100" [options]="{ prefix: '', thousands: ',', decimal: '.' }" id="total_amount" type="text" pInputText>
            <label for="total_amount">Total plan de pagos *</label>
          </span>
        </div>
        <div class="col-lg-2 col-md-12 my-2 md:my-0">
          <span class="p-float-label">
            <input currencyMask formControlName="diference" class="w-100" [options]="{ prefix: '', thousands: ',', decimal: '.' }" id="diference" type="text" pInputText>
            <label for="diference">Diferencia *</label>
          </span>
        </div>
      </div>
      <div *ngIf="diff <= -1 || diff >= 1" class="row mt-3">
        <div class="col-12">
          <p-message styleClass="w-100" severity="error" text="El monto de la diferencia no puede ser menor a -1 ni mayor a 1"></p-message>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p-divider align="left">
            <div class="inline-flex align-items-center">
                <i class="pi pi-cog mr-2"></i>
                <b>Configuración {{ selectedOption === 1 ? '(Manual)' : selectedOption === 2 ? '(%)' : selectedOption === 3 ? '(Montos)' : '' }}</b>
            </div>
          </p-divider>
        </div>
      </div>
      <div *ngIf="selectedOption === 1" class="row">
        <div class="col-12">
          <app-manual-quotes-list (totalPlan)="setTotalPlan($event)" (quotesList)="setQuotes($event)"></app-manual-quotes-list>
        </div>
      </div>
      <div *ngIf="selectedOption === 2" class="row">
        <div class="col-12">
          <app-percentage-plan [business]="business" (formData)="setPercentageForm($event)" [valid]="validPercentageForm" [messages]="percentMessages"></app-percentage-plan>
        </div>
      </div>
      <div *ngIf="selectedOption === 3" class="row">
        <div class="col-12">
          <app-amounts-plan [valid]="validAmountsForm" (formData)="setAmountsForm($event)"></app-amounts-plan>
        </div>
      </div>
    </form>
    <p-confirmDialog [style]="{width: '25vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text" acceptButtonStyleClass="p-button-text p-button-secondary" acceptLabel="Aceptar" rejectLabel="Cancelar"></p-confirmDialog>
  </ng-template>
  <ng-template pTemplate="footer">
    <p-button label="Crear plan" [disabled]="disabled" (click)="createPlan()" styleClass="p-button-sm p-button-secondary mr-2"></p-button>
    <p-button label="Cancelar" (click)="closeSidebar()" styleClass="p-button-sm p-button-text"></p-button>
  </ng-template>
</p-sidebar>
