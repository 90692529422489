import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ClientsService } from '@support/services/clients.service';
import { NotificationsService } from '@tools/services/notifications.service';
import { UserData } from '@core/models/user.model';
import { Subscription } from 'rxjs';
import { AuthService } from '@core/auth/services/auth.service';
import { Router } from '@angular/router';
import { CreateClientFromBusinessComponent } from '@support/pages/clients/create-client-from-business/create-client-from-business.component'
import { MatDialog } from '@angular/material/dialog';
import { BusinessService } from '@support/services/business.service';

@Component({
  selector: 'app-create-transfer-client',
  templateUrl: './create-transfer-client.component.html',
  styleUrls: ['./create-transfer-client.component.scss']
})

export class CreateTransferClientComponent implements OnInit, OnChanges {

  public clientsOptions: any[];
  public client_id: any;
  public default_client_name: string | null = null;
  public isLoading = false;
  private _subcriptions: Record<string, Subscription> = {};

  @Input() name: string;
  @Input() description: string;
  @Input() visible: boolean = true;
  @Input() isEdit: boolean = false;
  @Input() business: any = null;
  @Input() savedTeam: any = null;
  @Input() clients: any[] = null;

  @Output() emitVisible = new EventEmitter<boolean>();
  @Output() isSuccess = new EventEmitter<boolean>();
  @Output() updateClients = new EventEmitter<boolean>();

  public header: string = 'Cambio de clientes';
  public form: FormGroup;
  public currentUser: UserData;

  constructor(
    private fb: FormBuilder,
    private _clients:ClientsService,
    private _snackBar: NotificationsService,
    private _auth: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private _business:BusinessService,
  ) {
    this._subcriptions['user'] = this._auth.user$.subscribe(
      (user: UserData) => {
        if (user) {
          this.currentUser = user;
        }
      }
    );
  }

  ngOnInit(): void {
    this.setHeader();
    // this.getClients()
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.hasOwnProperty('business') && changes['business'].currentValue !== null) {
      this.business = changes['business'].currentValue;
      this.savedTeam = changes['business'].currentValue;
    } else {
      this.business = this.savedTeam;
    }

    if (changes.hasOwnProperty('visible') && changes['visible'].currentValue !== null && changes['visible'].currentValue) {
      if (this.savedTeam) {
        this.business = this.savedTeam;
        this.completeForm();
      }
    }

    if (changes.hasOwnProperty('clients') && changes['clients'].currentValue !== null && changes['clients'].currentValue) {
      this.clientsOptions = this.clients.filter((item) => item.id != this.business?.client.id ).map((item) => {
        return {
          ...item,
          name: item.fullname
        }
      });
      this.completeForm();
    }

    this.setHeader();
    this.createForm();

  }

  createForm(): void {
    this.form = this.fb.group({
      client_id: [null, Validators.required],
    });
    this.completeForm();
  }

  completeForm(): void {
    if (this.form && this.business) {

      this.form.patchValue({
        client_id: this.business?.client.id || null
      });

      this.client_id = this.business?.client.id || null;

      let client = this.clients.find((item) => item.id === this.business?.client.id)
      if (client) {
        this.default_client_name = client.name
      } else {
        this.default_client_name = null
      }

    }
  }

  getOptionName(options, id): string {
    let name = '';

    let object = options.find((item)=> item.id === id);

    name = object?.name;

    return name;
  }

  openCreateClientModal(): void {
    const dialogRef = this.dialog.open(CreateClientFromBusinessComponent, {
      data: {
        fromBusiness: true
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result.completed) {
        await this.updateClients.emit(true);
      }
    });
  }

  setHeader(): void {
    this.header = 'Cambio de cliente';
  }

  hideModal(): void {
    this.emitVisible.emit(false);
  }

  changeUsers(data): void {
    this.client_id = data.value || null;
  }

  createClient(): void {
    this.openCreateClientModal()
  }

  save(): void {
    if (this.client_id != this.business?.client.id) {
      if (this.form.invalid) {
        this._snackBar.openSnackBar('Por favor, complete los datos del formulario.', 'OK');
        this.form.markAllAsTouched();
        return;
      }
  
      let data = {
        ...this.form.getRawValue()
      }
  
      data.client_id = this.client_id || null
      data.business_id = this.business?.id || null;
  
      const url = this._business.transferClient(data);
  
      url.subscribe(
        (response) => {
          if(response.res) {
            this.isSuccess.emit(true);
          }
        },
        (error) => {
          console.log(error);
        }
      )
  
      this.hideModal();
    }
  }

  disableForm(): void {
  }

  getError(controlName: string) {
    const control = this.form.get(controlName);
    if (control && control.touched) {
      if (control.hasError('required')) {
        return 'Este campo es requerido';
      }
      if (control.hasError('pattern')) {
        return 'Debe indicar un valor válido';
      }
    }
    return '';
  }

  getErrorMessage(controlName: string): string {

    const field = this.form.get(controlName);

    if (!field || !field.invalid) return ''

    if (field.hasError('required')) {
      return 'Este campo es requerido';
    }

    if (field.hasError('max')) {
      return 'El valor actual excede el valor máximo permitido';
    }

    if (field.hasError('min')) {
      return 'El campo debe contener un valor mayor a 0.1';
    }

    return 'Debe ingresar un valor válido'
  }

  fiedIsInvalid(controlName: string) {
    const field = this.form.get(controlName);
    return (
      field &&
      field.invalid &&
      (field.touched)
    );
  }

}
